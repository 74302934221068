<template>
  <div class="login">
    <div class="login-container-img">
      <img src="../image/pc.jpg" v-if="$store.state.pageset.pcshow" />
      <img src="../image/moblie.jpg" v-else />
    </div>
    <div class="login-container">
      <div class="login-container-form" v-loading.fullscreen.lock="loading">
        <h1>Sign In</h1>
        <el-form ref="form" :model="form" v-if="!isSms">
          <el-form-item>
            <p class="p-normal">Account (phone number)</p>
            <el-input
              v-model="form.username"
              autofocus=""
              placeholder="Please enter your phone number"
              @keyup.enter.native="onSubmit"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <p class="p-normal">password</p>
            <el-input
              v-model="form.password"
              type="password"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="none"
              aria-label="password"
              required=""
              placeholder="Please enter password"
              @keyup.enter.native="onSubmit"
            ></el-input>
          </el-form-item>
        </el-form>
        <el-form ref="form" :model="smsForm" v-else>
          <el-form-item>
            <p class="p-normal">Mobile phone number</p>
            <el-input v-model="smsForm.phone" autofocus=""></el-input>
          </el-form-item>
          <el-form-item>
            <p class="p-normal">Verification code</p>
            <el-input v-model="smsForm.code" required="">
              <el-button
                :loading="smsLoading"
                :disabled="getCodeDisabled"
                slot="append"
                class="input-btn"
                @click="sendSms"
              >
                {{ getCodeBtnText }}</el-button
              >
            </el-input>
          </el-form-item>
        </el-form>

        <div v-if="isSms" class="sign-bottom-right" @click="changeType">
          <p class="p2">Log in via password</p>
        </div>
        <div v-else class="sign-bottom-right" @click="changeType">
          <p class="p1">Login via SMS</p>
        </div>
        <button type="submit" class="default-btn btn-submit" @click="onSubmit">
          Sign In
        </button>
        <button class="default-btn btn-tertiary" @click="cancelLogin">cancel</button>
        <span class="linebreak">or</span>
        <button class="default-btn btn-tertiary register-btn" @click="openRegister">
          Creat Account
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import index_en from "./index_en.js";

export default index_en;
</script>
<style scoped lang="scss">
@import "index_en.scss";
</style>
